import { graphql, useStaticQuery } from 'gatsby';
import AWSIcon from 'svgs/single-case-study/aws.svg';
import MobileDevelopmentIcon from 'svgs/single-case-study/mobile-development.svg';
import NodeJsIcon from 'svgs/single-case-study/node-js.svg';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

const faqPath = 'faq-section.content';
const FAQ_ITEMS = [
    {
        question: `${faqPath}.q1`,
        answer: `${faqPath}.a1`,
    },
    {
        question: `${faqPath}.q2`,
        answer: `${faqPath}.a2`,
    },
    {
        question: `${faqPath}.q3`,
        answer: `${faqPath}.a3`,
    },
    {
        question: `${faqPath}.q4`,
        answer: `${faqPath}.a4`,
    },
];

const content = contentResolver({
    project: 'cbreZone',
    brandColor: '--cbre-zone-case-study',
    brandColorGradient: {
        start: '--cbre-zone-case-study-gradient-start',
        stop: '--cbre-zone-case-study-gradient-stop',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.mobileDevelopment',
            Icon: MobileDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.nodeJs',
            Icon: NodeJsIcon,
        },
        {
            title: 'scopeOfWork.reactJs',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.reactNative',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AWSIcon,
        },
    ],
    projectInfo: {
        count: 3,
    },
    withBlockquote: true,
    paragraphsCounters: {
        header: 1,
        about: 3,
        challenge: 5,
        mainGoals: 3,
        solutions: 1,
        summary: 1,
    },
    caseStudies: ['desaLocum', 'turo', 'objekto'],
    caseStudiesSubtitle: 'case-studies.subtitle',
    faqTitle: 'faq-section.title',
    faqData: FAQ_ITEMS,
});

content.headerImageAlt = 'commercial real estate software';
content.about.alt = 'commercial real estate management software';
content.challenge.alt = 'commercial real estate property management software';
content.mainGoals.alt =
    'commercial real estate management software development';
content.solutions.alt =
    'commercial real estate property management software development';
content.summary.alt = 'commercial real estate application';

export const useCbre = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/cbre-zone/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/cbre-zone/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/cbre-zone/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/cbre-zone/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/cbre-zone/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/cbre-zone/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
